import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import {
  MdHome,
  MdOutlineMenu,
  MdOutlineMenuOpen,
  MdRestaurant,
} from "react-icons/md";
import MenuResponsivo from "./MenuResponsivo";

const Header = () => {
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const clickMenu = () => {
    setMostrarMenu(!mostrarMenu);
  };

  return (
    <header className="fixed z-20 top-0 right-0 w-full">
      <div className="bg-[url('/public/images/background4.jpg')] bg-cover py-3 ">
        <div className="mx-auto px-2 relative z-10">
          <div className="flex flex-row flex-wrap justify-between text-white">
            <Link
              to="/"
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
              className="text-xl md:text-3xl lg:text-4xl font-logo content-center font-extrabold hover:text-yellow-100"
            >
              Salesio's Lanchonete
            </Link>
            <nav className="hidden md:block">
              <ul className="flex items-center md:gap-14 lg:gap-32">
                <li className="py-1 flex gap-1">
                  <NavLink
                    className="content-center flex gap-1 border-slate-50 font-extrabold text-lg transition-all hover:text-yellow-100 hover:-translate-y-0.5 "
                    to="/"
                    onClick={() =>
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                    }
                  >
                    <MdHome size={30} />
                    Home
                  </NavLink>
                </li>
                <li className="flex gap-1">
                  <NavLink
                    className="content-center flex gap-1 font-extrabold text-lg transition-all hover:text-yellow-100 hover:-translate-y-0.5 "
                    to="/cardapio"
                    onClick={() =>
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                    }
                  >
                    <MdRestaurant size={30} />
                    Cardápio
                  </NavLink>
                </li>
              </ul>
            </nav>

            <p className="text-xl flex items-center md:text-xl lg:text-3xl font-bold ">
              <FaWhatsapp size={30} /> (43) 3328-8533
            </p>

            <div className="md:hidden hover:text-yellow-100">
              {mostrarMenu ? (
                <MdOutlineMenuOpen
                  onClick={clickMenu}
                  className="cursor-pointer transition-all"
                  size={40}
                />
              ) : (
                <MdOutlineMenu
                  onClick={clickMenu}
                  className="cursor-pointer transition-all "
                  size={40}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <MenuResponsivo
        mostrarMenu={mostrarMenu}
        setMostrarMenu={setMostrarMenu}
      />
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-gray-900 shadow-md shadow-stone-800"></div>
    </header>
  );
};

export default Header;
