import React from "react";
import { MdHome, MdRestaurant } from "react-icons/md";
import { Link } from "react-router-dom";

const MenuResponsivo = ({ mostrarMenu, setMostrarMenu }) => {
  return (
    <div>
      <div
        className={`${
          mostrarMenu ? "left-0" : "-left-[100%]"
        } fixed bottom-0 top-0 z-10 flex h-screen w-[50%] flex-col justify-between bg-[url('/public/images/background4.jpg')] text-white pt-16 transition-all duration-500 md:hidden`}
      ></div>
      <div
        className={`${
          mostrarMenu ? "left-0" : "-left-[100%]"
        } fixed bottom-0 top-0 z-10 flex h-screen w-[50%] flex-col justify-between bg-gradient-to-r from-transparent to-slate-900 shadow-md shadow-stone-800  pt-16 transition-all duration-500 md:hidden`}
      >
        <nav className="text-white p-6">
          <ul className="space-y-4 mt-12">
            <li className="flex py-1 gap-5">
              <Link
                className="content-center flex gap-3 font-extrabold text-lg transition-all hover:text-yellow-100"
                to="/"
                onClick={() => setMostrarMenu(false)}
              > <MdHome size={30} />
                Home
              </Link>
            </li>
            <li className="flex gap-5">
              
              <Link
                className="content-center flex gap-3 font-extrabold text-lg transition-all hover:text-yellow-100"
                to="/cardapio"
                onClick={() => setMostrarMenu(false)}
              > <MdRestaurant size={30} />
                Cardápio
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MenuResponsivo;
