import React from "react";
import { FaBrazilianRealSign } from "react-icons/fa6";

const Cardapio = ({ menu }) => {
  return (
    <div className="bg-[url('/public/images/background6.jpg')] bg-cover pt-5  md:p-6 lg:p-10 justify-evenly ">
        <div className="mt-20 md:mt-14 lg:mt-10 ml-10 border-l-8 pl-2 border-amber-500 text-slate-50 font-extrabold text-4xl tracking-wider">Lanches</div>
      <div className="grid grid-cols-1 md:grid-cols-2 bg lg:grid-cols-4 gap-6 md:gap-9 lg:gap-12 px-10 pt-10 ">
        {menu?.map((item) => (
          <div
            key={item.id}
            className="flex flex-col justify-between text-slate-50 my-2 shadow-inner border-2 border-slate-100 rounded-md text-base font-medium  transition-all duration-300 bg-slate-800 bg-opacity-50 hover:bg-opacity-75 hover:shadow-2xl hover:-translate-y-0.5 "
          >
            <img className="rounded-t-md" src={item.imagem} alt={item.item} />
            <div className="p-3">
              <h3 className="font-extrabold text-xl">{item.item}</h3>
              <p className="mt-1 grow text-slate-300">{item.descp}</p>
            </div>
            <p className="p-3 flex text-slate-50 items-center gap-2 font-extrabold text-xl">
              <FaBrazilianRealSign className="font-extrabold" size={20} />
              {item.valor}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cardapio;
